/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Media } from 'reactstrap';

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <Media>
      <Media left>
        <img
          src="/avatar.png"
          alt={author.name}
          className="author-avatar"
        />
      </Media>
      <Media body className="ml-3">
        <Media heading>
          {author.name}
        </Media>
        {author.summary}
      </Media>
    </Media>
  )
}

export default Bio
