import React from "react"
import { Link, graphql } from "gatsby"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Gist from 'react-gist'
import { InfoPanel } from "../components/panel"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { DiscussionEmbed } from "disqus-react"
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  const disqusConfig = {
    shortname: 'tejasjadhav',
    config: { identifier: post.frontmatter.slug, title: post.frontmatter.title },
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1>
            {post.frontmatter.title}
          </h1>
          <p>
            {post.frontmatter.date}
          </p>
        </header>

        <MDXProvider components={{
          InfoPanel,
          Gist,
        }}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>

        <hr />

        <DiscussionEmbed {...disqusConfig} />

        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <Pagination listClassName="justify-content-center">
          {previous && (
            <PaginationItem>
              <PaginationLink tag="div">
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              </PaginationLink>
            </PaginationItem>
          )}
          {next && (
            <PaginationItem>
              <PaginationLink tag="div">
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
