import React from 'react';

import { Card, CardBody, CardTitle } from 'reactstrap';

export function InfoPanel(props) {
  const title = props.title || "Tip";

  return (<Card outline color="info" className="panel mt-2 mb-2">
    <CardBody>
      <CardTitle tag="h4">💡 {title}</CardTitle>
      {props.children}
    </CardBody>
  </Card>);
}
